import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { [key: string]: any }
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any } | { [key: string]: any }[]
}

export type PagerResult = {
  __typename?: 'PagerResult'
  currentPage: Scalars['Int']
  pageSize: Scalars['Int']
  total: Scalars['Int']
  hasMore: Scalars['Boolean']
}

export type BaseResult = {
  __typename?: 'BaseResult'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type HospitalInfo = {
  __typename?: 'HospitalInfo'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  contact?: Maybe<Scalars['String']>
}

export type ContentActionBaseInfo = {
  __typename?: 'ContentActionBaseInfo'
  id: Scalars['String']
  contentId: Scalars['String']
  doctor?: Maybe<AccountInfo>
  actionTime: Scalars['String']
  doctorAccountId: Scalars['String']
  salesAccountId: Scalars['String']
  companyId: Scalars['String']
  type: ContentActionType
  value?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  gmtCreated: Scalars['String']
  gmtModified: Scalars['String']
}

export enum ContentActionType {
  Share = 'SHARE',
  Read = 'READ',
  Reshare = 'RESHARE',
  ReadTime = 'READ_TIME',
  Up = 'UP',
  Comment = 'COMMENT'
}

export type ContentActionStatInfo = {
  __typename?: 'ContentActionStatInfo'
  type: ContentActionType
  value?: Maybe<Scalars['Int']>
  period?: Maybe<StatPeriod>
  quota: Scalars['Float']
}

export enum StatPeriod {
  All = 'ALL',
  Day = 'DAY',
  CurrentWeek = 'CURRENT_WEEK',
  CurrentMonth = 'CURRENT_MONTH',
  RecentWeek = 'RECENT_WEEK',
  RecentMonth = 'RECENT_MONTH'
}

export type ContentInfo = {
  __typename?: 'ContentInfo'
  id?: Maybe<Scalars['String']>
  type: ContentType
  title: Scalars['String']
  quizId: Scalars['String']
  creatorAccountId: Scalars['String']
  releaseTime: Scalars['String']
  gmtCreated: Scalars['String']
  gmtModified: Scalars['String']
  companyId?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  contentActions?: Maybe<Scalars['JSONObject']>
  contentStats?: Maybe<Array<ContentActionStatInfo>>
  hasLike: Scalars['Boolean']
  comments: Array<ContentActionBaseInfo>
}

export type ContentInfoHasLikeArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
}

export enum ContentType {
  Article = 'ARTICLE',
  Video = 'VIDEO'
}

export type ContentListQueryResult = {
  __typename?: 'ContentListQueryResult'
  items: Array<ContentInfo>
  pager: PagerResult
}

export type ContentActionInfo = {
  __typename?: 'ContentActionInfo'
  id: Scalars['String']
  contentId: Scalars['String']
  doctor?: Maybe<AccountInfo>
  actionTime: Scalars['String']
  doctorAccountId: Scalars['String']
  salesAccountId: Scalars['String']
  companyId: Scalars['String']
  type: ContentActionType
  value?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  gmtCreated: Scalars['String']
  gmtModified: Scalars['String']
  content?: Maybe<ContentInfo>
  hasDoctorRead: Scalars['Boolean']
}

export type SalesPerformance = {
  __typename?: 'SalesPerformance'
  share: ContentActionStatInfo
  unshare: ContentActionStatInfo
  up: ContentActionStatInfo
  read: ContentActionStatInfo
}

export type SalesPerformanceData = {
  __typename?: 'SalesPerformanceData'
  period: StatPeriod
  data: Scalars['JSONObject']
}

export type AccountGroupInfo = {
  __typename?: 'AccountGroupInfo'
  groupId: Scalars['String']
  name: Scalars['String']
  pid?: Maybe<Scalars['String']>
  parent?: Maybe<AccountGroupInfo>
}

export type DepartmentInfo = {
  __typename?: 'DepartmentInfo'
  departmentId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  status?: Maybe<GroupStatus>
}

/** 组织状态 */
export enum GroupStatus {
  GroupActivated = 'GROUP_ACTIVATED',
  GroupDisabled = 'GROUP_DISABLED'
}

export type CompanyInfo = {
  __typename?: 'CompanyInfo'
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type AccountBaseInfo = {
  __typename?: 'AccountBaseInfo'
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
}

/** 员工状态 */
export enum AccountStatus {
  AccountActivated = 'ACCOUNT_ACTIVATED',
  AccountDisabled = 'ACCOUNT_DISABLED',
  AccountInactive = 'ACCOUNT_INACTIVE',
  AccountReviewPending = 'ACCOUNT_REVIEW_PENDING',
  AccountReviewRefused = 'ACCOUNT_REVIEW_REFUSED'
}

export type AccountInfo = {
  __typename?: 'AccountInfo'
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
  company?: Maybe<CompanyInfo>
  reportAccount?: Maybe<AccountInfo>
  department?: Maybe<DepartmentInfo>
  group?: Maybe<AccountGroupInfo>
  hospital?: Maybe<HospitalInfo>
  authenticated: Scalars['Boolean']
  contentActionStats: Array<ContentActionStatInfo>
  roles: Array<RoleInfo>
}

export type AccountInfoGroupArgs = {
  groupType: Scalars['String']
}

export type AccountInfoContentActionStatsArgs = {
  contentId?: Maybe<Scalars['String']>
}

export type TargetRegionNodeBaseInfo = {
  __typename?: 'TargetRegionNodeBaseInfo'
  groupId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TargetRegionNodeInfo = {
  __typename?: 'TargetRegionNodeInfo'
  groupId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  children: Array<TargetRegionNodeInfo>
}

export type MemberPaginatedResponse = {
  __typename?: 'MemberPaginatedResponse'
  items: Array<AccountInfo>
  pager: PagerResult
}

export type GroupPaginationRes = {
  __typename?: 'GroupPaginationRes'
  items: Array<AccountGroupInfo>
  pager: PagerResult
}

export type HospitalPaginationRes = {
  __typename?: 'HospitalPaginationRes'
  items: Array<HospitalInfo>
  pager: PagerResult
}

export type IesLocation = {
  __typename?: 'IESLocation'
  lat?: Maybe<Scalars['Float']>
  lon?: Maybe<Scalars['Float']>
}

export type RestaurantInfo = {
  __typename?: 'RestaurantInfo'
  indexId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  restaurantId: Scalars['String']
  companys: Array<Scalars['String']>
  name?: Maybe<Scalars['String']>
  restaurantName: Scalars['String']
  district?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  areaCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  cityCode?: Maybe<Scalars['String']>
  businessAreaName?: Maybe<Scalars['String']>
  businessAreaCode?: Maybe<Scalars['String']>
  cityNameTag?: Maybe<Scalars['String']>
  areaNameTag?: Maybe<Scalars['String']>
  businessAreaNameTag?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  location?: Maybe<IesLocation>
  cookStyle?: Maybe<Array<Scalars['String']>>
  category?: Maybe<Array<Scalars['String']>>
  cookStyleTag?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['Float']>
  consume?: Maybe<Scalars['Float']>
  indoor?: Maybe<Array<Scalars['String']>>
  indoorUrls?: Maybe<Array<Scalars['String']>>
  outdoor?: Maybe<Array<Scalars['String']>>
  outdoorUrl?: Maybe<Array<Scalars['String']>>
  createTime?: Maybe<Scalars['Timestamp']>
  needAdvance?: Maybe<Scalars['Boolean']>
  advanceHour?: Maybe<Scalars['Float']>
  hitNum?: Maybe<Scalars['Float']>
  onLineTime: Scalars['Timestamp']
  settlementType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  distance?: Maybe<Scalars['Float']>
  gaodeId?: Maybe<Scalars['String']>
  txType?: Maybe<Scalars['String']>
  telPhone?: Maybe<Scalars['String']>
  supportWaimai: Scalars['Boolean']
  supportYuding: Scalars['Boolean']
  shippingDis?: Maybe<Scalars['Float']>
  advanceTime?: Maybe<Scalars['Float']>
  deliveryDesc?: Maybe<Scalars['String']>
  shippingFeeMin?: Maybe<Scalars['Float']>
  cancelRule?: Maybe<Scalars['String']>
  maxItems?: Maybe<Scalars['Float']>
  maxType?: Maybe<Scalars['String']>
  openingHours?: Maybe<Scalars['String']>
  introduce?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['Float']>
  frozenAmount?: Maybe<Scalars['Float']>
  availableAmount?: Maybe<Scalars['Float']>
  companyName?: Maybe<Scalars['String']>
  balanceAcountId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  clientBlacklists: Array<Scalars['String']>
  onlineStatus?: Maybe<Scalars['String']>
  settlementOwner?: Maybe<Scalars['String']>
  rebateId?: Maybe<Scalars['String']>
  rebateType?: Maybe<Scalars['String']>
  rebateRule?: Maybe<Scalars['String']>
  rebateSettlementType?: Maybe<Scalars['String']>
  effectiveTime: Scalars['Timestamp']
  rebateStatus?: Maybe<Scalars['String']>
  rebatePercent?: Maybe<Scalars['Float']>
  rebateBizType?: Maybe<Scalars['String']>
  companyNameTag?: Maybe<Scalars['String']>
  einvoice?: Maybe<Scalars['Boolean']>
  shippingFee?: Maybe<Scalars['Float']>
  packingBoxFee?: Maybe<Scalars['Float']>
  polygon?: Maybe<Scalars['String']>
  isPolygon?: Maybe<Scalars['Float']>
  haveBox?: Maybe<Scalars['Boolean']>
  boxNum?: Maybe<Scalars['Float']>
  boxMinConsume?: Maybe<Scalars['String']>
  boxMinimum?: Maybe<Scalars['String']>
}

export type RestaurantSearchResult = {
  __typename?: 'RestaurantSearchResult'
  items: Array<RestaurantInfo>
  pager: PagerResult
}

export type LocationInfo = {
  __typename?: 'LocationInfo'
  lat?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  landMark?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
}

export type CarOrderScheduleInfo = {
  __typename?: 'CarOrderScheduleInfo'
  scheduleId?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  origin?: Maybe<LocationInfo>
  departureTime?: Maybe<Scalars['String']>
  destination?: Maybe<LocationInfo>
  contactPeople?: Maybe<Scalars['String']>
  contactPeoplePhone?: Maybe<Scalars['String']>
  predictCost?: Maybe<Scalars['Float']>
  passenger?: Maybe<Scalars['String']>
  passengerPhone?: Maybe<Scalars['String']>
  model?: Maybe<BaeyCarModel>
  peopleNum?: Maybe<Scalars['Int']>
  totalCost?: Maybe<Scalars['Float']>
  liquidatedDamages?: Maybe<Scalars['Float']>
  rentType?: Maybe<BaeyCarRentType>
  flightDate?: Maybe<Scalars['String']>
  flightNumber?: Maybe<Scalars['String']>
  flightDepartDelayTime?: Maybe<Scalars['Timestamp']>
}

export enum BaeyCarModel {
  Economic = 'economic',
  Comfortable = 'comfortable',
  Business = 'business',
  Luxurious = 'luxurious',
  Minibus = 'minibus',
  Midibus = 'midibus',
  Bigbus = 'bigbus'
}

export enum BaeyCarRentType {
  Btbc = 'BTBC',
  Qtbc = 'QTBC',
  Jjz = 'JJZ',
  Sjz = 'SJZ',
  Snjs = 'SNJS',
  Ksjs = 'KSJS'
}

export type CarOrderInfo = {
  __typename?: 'CarOrderInfo'
  orderId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  serviceType?: Maybe<Scalars['String']>
  rentType?: Maybe<Scalars['String']>
  supplier?: Maybe<Scalars['String']>
  predictCost?: Maybe<Scalars['Float']>
  actualCost?: Maybe<Scalars['Float']>
  peopleNum?: Maybe<Scalars['Float']>
  departureTime?: Maybe<Scalars['Timestamp']>
  destination?: Maybe<LocationInfo>
  origin?: Maybe<LocationInfo>
  status?: Maybe<Scalars['String']>
  schedule?: Maybe<Array<CarOrderScheduleInfo>>
}

export type CarServiceInfo = {
  __typename?: 'CarServiceInfo'
  id: Scalars['String']
  serviceType?: Maybe<Scalars['String']>
  servicePrice?: Maybe<Scalars['String']>
  serviceDesc: Scalars['String']
  timeOutPrice?: Maybe<Scalars['String']>
  timeOutDesc?: Maybe<Scalars['String']>
  overDistancePrice?: Maybe<Scalars['String']>
  overDistanceDesc?: Maybe<Scalars['String']>
}

export type CarInfo = {
  __typename?: 'CarInfo'
  carType?: Maybe<Scalars['String']>
  carTypeDesc?: Maybe<Scalars['String']>
  carService?: Maybe<Array<CarServiceInfo>>
}

export type CarOfferPrice = {
  __typename?: 'CarOfferPrice'
  id?: Maybe<Scalars['String']>
  supplier?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['String']>
  carType?: Maybe<Scalars['String']>
  serviceType?: Maybe<Scalars['String']>
}

export type PaginatedCarOrderResponse = {
  __typename?: 'PaginatedCarOrderResponse'
  items: Array<CarOrderInfo>
  pager: PagerResult
}

export type MeetingUplatformHotelTempDemo = {
  __typename?: 'MeetingUplatformHotelTempDemo'
  id: Scalars['String']
  name: Scalars['String']
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
  bizExt?: Maybe<Scalars['String']>
}

export type MeetingUplatformOrder = {
  __typename?: 'MeetingUplatformOrder'
  id?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  budgetId?: Maybe<Scalars['String']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<VendorType>
}

export enum VendorType {
  Restaurant = 'RESTAURANT',
  Hotel = 'HOTEL',
  Vehicle = 'VEHICLE'
}

export type BizStatusInfo = {
  __typename?: 'BizStatusInfo'
  text: Scalars['String']
  color: Scalars['String']
}

export type BizBtnInfo = {
  __typename?: 'BizBtnInfo'
  title: Scalars['String']
  action: Scalars['String']
  btnProps?: Maybe<Scalars['JSONObject']>
}

export type LogData = {
  __typename?: 'LogData'
  logType?: Maybe<Scalars['String']>
  assginer?: Maybe<Scalars['String']>
  assginerInfo?: Maybe<AccountInfo>
  reason?: Maybe<Scalars['String']>
}

export type TaskInfo = {
  __typename?: 'TaskInfo'
  taskId?: Maybe<Scalars['String']>
  approver?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  nodeSort?: Maybe<Scalars['Int']>
  approverInfo?: Maybe<AccountInfo>
  logDatas?: Maybe<Array<LogData>>
}

export type ApproveInfo = {
  __typename?: 'ApproveInfo'
  instanceId?: Maybe<Scalars['String']>
  processId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  tasks?: Maybe<Array<TaskInfo>>
}

export type MeetingUplatformBudget = {
  __typename?: 'MeetingUplatformBudget'
  id: Scalars['String']
  type?: Maybe<Scalars['String']>
  typeName?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Float']>
  attributes?: Maybe<Scalars['JSONObject']>
  note?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
  orders: Array<MeetingOrderInfo>
}

export type MeetingUplatformQuotation = {
  __typename?: 'MeetingUplatformQuotation'
  id?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  tax?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<VendorType>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type MeetingUplatformQuotationHistory = {
  __typename?: 'MeetingUplatformQuotationHistory'
  id?: Maybe<Scalars['ID']>
  originalId?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  price?: Maybe<Scalars['Float']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type MeetingQuotationInfo = {
  __typename?: 'MeetingQuotationInfo'
  id?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  tax?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<VendorType>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
  vendor?: Maybe<VendorUnion>
  total?: Maybe<Scalars['Float']>
}

export type VendorUnion =
  | RestaurantInfo
  | MeetingUplatformHotelTempDemo
  | CarServiceInfo

export type MeetingUplatformSettlement = {
  __typename?: 'MeetingUplatformSettlement'
  id?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  externalOrderId?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  tax?: Maybe<Scalars['Float']>
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  tickets?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type MeetingSettlementInfo = {
  __typename?: 'MeetingSettlementInfo'
  id?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  externalOrderId?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  tax?: Maybe<Scalars['Float']>
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  tickets?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
  vendor?: Maybe<VendorUnion>
  total?: Maybe<Scalars['Float']>
}

export type MeetingOrderInfo = {
  __typename?: 'MeetingOrderInfo'
  id?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  budgetId?: Maybe<Scalars['String']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<VendorType>
  typeName?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Float']>
  quotations: Array<MeetingQuotationInfo>
  settlements: Array<MeetingSettlementInfo>
  vendor?: Maybe<VendorUnion>
  budget?: Maybe<MeetingUplatformBudget>
  subType?: Maybe<Scalars['String']>
  relatedBizOrder?: Maybe<Scalars['JSON']>
  relatedBizOrders?: Maybe<Scalars['JSON']>
}

export type MeetingOrderInfoQuotationsArgs = {
  type?: Maybe<QuotationQueryType>
}

export enum QuotationQueryType {
  History = 'HISTORY',
  Current = 'CURRENT'
}

export type MeetingUplatformAttender = {
  __typename?: 'MeetingUplatformAttender'
  id: Scalars['String']
  type?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  accountInfo?: Maybe<AccountInfo>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type RoleInfo = {
  __typename?: 'RoleInfo'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  enable?: Maybe<Scalars['Boolean']>
}

export type MeetingUplatformSchedule = {
  __typename?: 'MeetingUplatformSchedule'
  id: Scalars['String']
  meetingId?: Maybe<Scalars['ID']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  startTime: Scalars['Timestamp']
  endTime: Scalars['Timestamp']
  topic: Scalars['String']
  place?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
  livePhotos?: Maybe<Scalars['JSON']>
}

export type CityInfo = {
  __typename?: 'CityInfo'
  id: Scalars['String']
  name: Scalars['String']
  nameEn: Scalars['String']
  initialEn: Scalars['String']
  pid: Scalars['String']
  pCity?: Maybe<CityInfo>
}

export type MeetingBudgetsEntry = {
  __typename?: 'MeetingBudgetsEntry'
  items: Array<MeetingUplatformBudget>
  total: Scalars['Float']
}

export type MeetingInfo = {
  __typename?: 'MeetingInfo'
  id?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  owner?: Maybe<AccountInfo>
  applierId?: Maybe<Scalars['String']>
  applier?: Maybe<AccountInfo>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  type?: Maybe<UpMeetingType>
  bmsType?: Maybe<Scalars['String']>
  status?: Maybe<UpMeetingStatus>
  subStatus?: Maybe<Scalars['String']>
  statusText?: Maybe<Scalars['String']>
  /** @deprecated use new field bizStatus */
  approveStatus?: Maybe<BizStatusInfo>
  bizStatus?: Maybe<BizStatusInfo>
  approve?: Maybe<ApproveInfo>
  department?: Maybe<Scalars['String']>
  hotel?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  city?: Maybe<CityInfo>
  cityName?: Maybe<Scalars['String']>
  hospitals?: Maybe<Array<HospitalInfo>>
  hospitalIds?: Maybe<Array<Scalars['String']>>
  insideAttenderNum?: Maybe<Scalars['Int']>
  outsideAttenderNum?: Maybe<Scalars['Int']>
  peopleNum?: Maybe<Scalars['Int']>
  period?: Maybe<UpMeetingPeriod>
  budgets: MeetingBudgetsEntry
  schedules: Array<MeetingUplatformSchedule>
  attenders: Array<MeetingUplatformAttender>
  hasSpeaker?: Maybe<Scalars['Boolean']>
  /** @deprecated use new field actionBtns */
  approveBtns: Array<BizBtnInfo>
  actionBtns: Array<BizBtnInfo>
  hintMessage?: Maybe<Scalars['String']>
  bmsPOBill?: Maybe<Scalars['String']>
  bmsMarkedPaper?: Maybe<Scalars['String']>
  bmsMeetingHotel?: Maybe<BmsMeetingHotel>
  bmsMeetingAbnormalCount?: Maybe<Scalars['Int']>
  approveReason?: Maybe<Scalars['String']>
  carOrders?: Maybe<Scalars['JSON']>
  bmsBizStatus?: Maybe<BizStatusInfo>
}

export type MeetingInfoAttendersArgs = {
  type?: Maybe<AttenderType>
}

export type MeetingInfoApproveBtnsArgs = {
  in?: Maybe<Scalars['String']>
}

export type MeetingInfoActionBtnsArgs = {
  in?: Maybe<Scalars['String']>
}

export type MeetingInfoCarOrdersArgs = {
  orderId?: Maybe<Scalars['String']>
}

/** u平台会议类型 */
export enum UpMeetingType {
  UDepartment = 'U_DEPARTMENT',
  UHospital = 'U_HOSPITAL',
  UCity = 'U_CITY',
  UArea = 'U_AREA',
  UResearch = 'U_RESEARCH'
}

/** u平台会议状态 */
export enum UpMeetingStatus {
  UMeetingDraft = 'U_MEETING_DRAFT',
  UMeetingWithdraw = 'U_MEETING_WITHDRAW',
  UMeetingApproving = 'U_MEETING_APPROVING',
  UMeetingQuotation = 'U_MEETING_QUOTATION',
  UMeetingInProgress = 'U_MEETING_IN_PROGRESS',
  UMeetingInSettlement = 'U_MEETING_IN_SETTLEMENT',
  UMeetingFinished = 'U_MEETING_FINISHED',
  UMeetingForbidden = 'U_MEETING_FORBIDDEN',
  UMeetingCanceled = 'U_MEETING_CANCELED'
}

/** u平台会议时段 */
export enum UpMeetingPeriod {
  Morning = 'MORNING',
  Evening = 'EVENING',
  Beforenoon = 'BEFORENOON',
  Afternoon = 'AFTERNOON',
  Allday = 'ALLDAY'
}

/** 参会人员类型 */
export enum AttenderType {
  Inside = 'INSIDE',
  Outside = 'OUTSIDE'
}

export type MeetingSearchResult = {
  __typename?: 'MeetingSearchResult'
  items: Array<MeetingInfo>
  pager: PagerResult
}

export type UpMeetingStatusOption = {
  __typename?: 'UPMeetingStatusOption'
  value: UpMeetingStatus
  label: Scalars['String']
}

export type UpMeetingQuotationStatusOption = {
  __typename?: 'UPMeetingQuotationStatusOption'
  value: UpMeetingQuotationStatus
  label: Scalars['String']
}

/** u平台报价阶段状态 */
export enum UpMeetingQuotationStatus {
  UAwaitOrder = 'U_AWAIT_ORDER',
  UAwaitQuotation = 'U_AWAIT_QUOTATION',
  UAwaitConfirm = 'U_AWAIT_CONFIRM',
  UAwaitApproval = 'U_AWAIT_APPROVAL'
}

export type UpMeetingSignInInfo = {
  __typename?: 'UPMeetingSignInInfo'
  accountId: Scalars['String']
  meetingId: Scalars['String']
  address: Scalars['String']
  time: Scalars['String']
  userInfo?: Maybe<Scalars['JSONObject']>
}

export type CarTravelCreat = {
  __typename?: 'CarTravelCreat'
  num: Scalars['String']
  carType: Scalars['String']
  type: Scalars['String']
  unit: Scalars['String']
  note: Scalars['String']
}

export type BmsLoginResponse = {
  __typename?: 'BmsLoginResponse'
  token: Scalars['String']
  account: AccountInfo
}

export type BmsMeetingHotel = {
  __typename?: 'BmsMeetingHotel'
  name: Scalars['String']
  address?: Maybe<Scalars['String']>
  position: Scalars['String']
  amapId: Scalars['String']
  city: Scalars['String']
}

export type BmsMeetingStatusOption = {
  __typename?: 'BmsMeetingStatusOption'
  value: BmsMeetingStatus
  label: Scalars['String']
}

export enum BmsMeetingStatus {
  WaitTravelUpload = 'WAIT_TRAVEL_UPLOAD',
  WaitQuotation = 'WAIT_QUOTATION',
  WaitQuotationToConfirm = 'WAIT_QUOTATION_TO_CONFIRM',
  InProgress = 'IN_PROGRESS',
  WaitConfirm = 'WAIT_CONFIRM',
  Finished = 'FINISHED',
  Canceled = 'CANCELED'
}

export type BmsCarOrderDetail = {
  __typename?: 'BmsCarOrderDetail'
  order?: Maybe<Scalars['JSON']>
  meeting?: Maybe<MeetingInfo>
}

export type MeetingUplatformProcess = {
  __typename?: 'MeetingUplatformProcess'
  id: Scalars['String']
  type?: Maybe<Scalars['String']>
  taskId?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  actionerId?: Maybe<Scalars['String']>
  actionId?: Maybe<Scalars['String']>
  action?: Maybe<Scalars['String']>
  actioner?: Maybe<AccountInfo>
  reason?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  stage?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  done?: Maybe<Scalars['Boolean']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type ResourceInfo = {
  __typename?: 'ResourceInfo'
  resourceId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  type?: Maybe<ResourceType>
}

export enum ResourceType {
  Ppt = 'PPT',
  Pdf = 'PDF',
  Video = 'VIDEO',
  Image = 'IMAGE'
}

export type StandardMeetingBudgetInfo = {
  __typename?: 'StandardMeetingBudgetInfo'
  LectureFee?: Maybe<Scalars['Float']>
  otherFee?: Maybe<Scalars['Float']>
}

export type ProductInfo = {
  __typename?: 'ProductInfo'
  id: Scalars['String']
  name: Scalars['String']
  isOtc?: Maybe<Scalars['Boolean']>
}

export type WatchUrlInfo = {
  __typename?: 'WatchUrlInfo'
  pc?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
}

export type WebLiveToolInfo = {
  __typename?: 'WebLiveToolInfo'
  hostLoginAddr?: Maybe<Scalars['String']>
  hostLoginToken?: Maybe<Scalars['String']>
  guestLoginAddr?: Maybe<Scalars['String']>
  guestLoginToken?: Maybe<Scalars['String']>
}

export type StandardMeetingBaseData = {
  __typename?: 'StandardMeetingBaseData'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  applyTime?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  company?: Maybe<CompanyInfo>
  ownerId?: Maybe<Scalars['String']>
  owner?: Maybe<AccountInfo>
  topic?: Maybe<Scalars['String']>
  desc?: Maybe<Scalars['String']>
  status?: Maybe<StandardMeetingStatus>
  type?: Maybe<StandardMeetingType>
  peopleNum?: Maybe<Scalars['Int']>
  channel?: Maybe<StandardMeetingChannel>
}

export enum StandardMeetingStatus {
  NotStart = 'NOT_START',
  Ongoing = 'ONGOING',
  Finish = 'FINISH',
  Exception = 'EXCEPTION',
  Deleted = 'DELETED'
}

export enum StandardMeetingType {
  OnlineLive = 'ONLINE_LIVE',
  OnlineRecord = 'ONLINE_RECORD',
  Offline = 'OFFLINE',
  MedicalResearchProject = 'MEDICAL_RESEARCH_PROJECT',
  AcademicConference = 'ACADEMIC_CONFERENCE'
}

export enum StandardMeetingChannel {
  PingGuang = 'PING_GUANG',
  HrVideo = 'HR_VIDEO'
}

export type StandardMeetingProperties = {
  __typename?: 'StandardMeetingProperties'
  topicPictureUrls: Array<Scalars['String']>
  speaker?: Maybe<Scalars['String']>
  pptUrl: Array<Scalars['String']>
  liveUrl?: Maybe<WatchUrlInfo>
  needQuiz?: Maybe<Scalars['Boolean']>
  needAssistance?: Maybe<Scalars['Boolean']>
  budget?: Maybe<StandardMeetingBudgetInfo>
  approvalStatus?: Maybe<StandardMeetingApprovalStatus>
  products: Array<ProductInfo>
  webLiveTool?: Maybe<WebLiveToolInfo>
}

export enum StandardMeetingApprovalStatus {
  Approving = 'APPROVING',
  NotApproval = 'NOT_APPROVAL',
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

export type StandardMeetingOtherProperties = {
  __typename?: 'StandardMeetingOtherProperties'
  type: StandardMeetingPropType
  value?: Maybe<Scalars['String']>
  diyType?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export enum StandardMeetingPropType {
  Diy = 'DIY',
  QuizUrl = 'QUIZ_URL',
  LiveUrl = 'LIVE_URL',
  MeetingProduct = 'MEETING_PRODUCT',
  MeetingSpeaker = 'MEETING_SPEAKER',
  MeetingChairman = 'MEETING_CHAIRMAN',
  MeetingRedenvelopeTotalmoney = 'MEETING_REDENVELOPE_TOTALMONEY',
  LiveId = 'LIVE_ID',
  NeedQuiz = 'NEED_QUIZ',
  PlayerId = 'PLAYER_ID',
  ThumbUrl = 'THUMB_URL',
  TopicPicUrl = 'TOPIC_PIC_URL',
  SourceMeetingInfo = 'SOURCE_MEETING_INFO',
  QuizStartTime = 'QUIZ_START_TIME',
  LiveAuthoKey = 'LIVE_AUTHO_KEY',
  Hospital = 'HOSPITAL',
  OpenRegistration = 'OPEN_REGISTRATION',
  Location = 'LOCATION',
  Area = 'AREA',
  BudgetCycle = 'BUDGET_CYCLE',
  PptUrl = 'PPT_URL',
  ApprovalStatus = 'APPROVAL_STATUS',
  Budget = 'BUDGET',
  NeedAssistance = 'NEED_ASSISTANCE',
  WebLiveTool = 'WEB_LIVE_TOOL',
  MuduLiveExcelBill = 'MUDU_LIVE_EXCEL_BILL',
  MuduLiveExcelDetail = 'MUDU_LIVE_EXCEL_DETAIL',
  ProjectType = 'PROJECT_TYPE',
  ContractTemplateId = 'CONTRACT_TEMPLATE_ID',
  MeetingLiveId = 'MEETING_LIVE_ID',
  ServiceBusinessStatus = 'SERVICE_BUSINESS_STATUS',
  City = 'CITY',
  Province = 'PROVINCE'
}

export type StandardMeetingInfo = {
  __typename?: 'StandardMeetingInfo'
  meetingBaseData?: Maybe<StandardMeetingBaseData>
  properties?: Maybe<StandardMeetingProperties>
  city?: Maybe<AccountGroupInfo>
  ppts: Array<ResourceInfo>
  speakerInfo?: Maybe<AccountInfo>
  authLiveUrl?: Maybe<Scalars['String']>
  isOwner: Scalars['Boolean']
  isCurrentApprover: Scalars['Boolean']
  otherProperties?: Maybe<Array<StandardMeetingOtherProperties>>
  hengruiImage_approveStatus?: Maybe<Scalars['String']>
  hengruiImage_approveReason?: Maybe<Scalars['String']>
  hengruiImage_costs: Array<StandardMeetingCost>
  hengruiImage_spearkers?: Maybe<Array<StandardMeetingBaseAttender>>
  hengruiImage_attendDoctors?: Maybe<Array<StandardMeetingBaseAttender>>
  hengruiImage_projectType?: Maybe<Scalars['String']>
  hengruiImage_address?: Maybe<Scalars['String']>
  hengruiImage_hospital?: Maybe<Scalars['String']>
  hengruiImage_materials?: Maybe<Array<Scalars['String']>>
  hengruiImage_schedulePics?: Maybe<Array<Scalars['String']>>
  hengruiImage_checkReports?: Maybe<Array<Scalars['String']>>
  hengruiImage_status?: Maybe<Scalars['String']>
  hengruiImage_statusText?: Maybe<Scalars['String']>
  hengruiImage_city?: Maybe<CityInfo>
  hengruiSpearks: Array<HengruiMeetingSpeaker>
}

export type PageQueryStandardMeetingResult = {
  __typename?: 'PageQueryStandardMeetingResult'
  items: Array<StandardMeetingInfo>
  pager: PagerResult
}

export type HrMeetingStatusOption = {
  __typename?: 'HRMeetingStatusOption'
  value: StandardMeetingServiceBizStatus
  label: Scalars['String']
}

export enum StandardMeetingServiceBizStatus {
  MeetingInProgress = 'MEETING_IN_PROGRESS',
  MeetingInApproving = 'MEETING_IN_APPROVING',
  MeetingRetreated = 'MEETING_RETREATED',
  MeetingInSettlement = 'MEETING_IN_SETTLEMENT',
  MeetingFinished = 'MEETING_FINISHED',
  MeetingSettlementWithoutTax = 'MEETING_SETTLEMENT_WITHOUT_TAX'
}

export type StandardMeetingBaseAttender = {
  __typename?: 'StandardMeetingBaseAttender'
  attenderData?: Maybe<Scalars['JSON']>
  attenderId?: Maybe<Scalars['String']>
  attenderName?: Maybe<Scalars['String']>
  attenderPhone?: Maybe<Scalars['String']>
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
  attenderInfo?: Maybe<AccountInfo>
}

export enum StandardMeetingTypeAttenderType {
  Creator = 'CREATOR',
  Speaker = 'SPEAKER',
  Attender = 'ATTENDER',
  Guest = 'GUEST',
  Subscriber = 'SUBSCRIBER'
}

export type HengruiMeetingSpeaker = {
  __typename?: 'HengruiMeetingSpeaker'
  name: Scalars['String']
  accountId: Scalars['String']
  mobile: Scalars['String']
  topic?: Maybe<Scalars['String']>
  fee: Scalars['String']
  duration?: Maybe<Scalars['Int']>
  materials?: Maybe<Array<Scalars['String']>>
  data?: Maybe<Scalars['JSON']>
}

export type StandardMeetingCost = {
  __typename?: 'StandardMeetingCost'
  amount: Scalars['String']
  type: Scalars['String']
  note?: Maybe<Scalars['String']>
}

export type MeetingBudgetTypeOption = {
  __typename?: 'MeetingBudgetTypeOption'
  value: UpMeetingBudgetType
  label: Scalars['String']
}

/** u平台会议预算类型 */
export enum UpMeetingBudgetType {
  MeetingPlace = 'MEETING_PLACE',
  Accommodation = 'ACCOMMODATION',
  Dining = 'DINING',
  LongDistanceTransportationFee = 'LONG_DISTANCE_TRANSPORTATION_FEE',
  ShortDistanceTransportationFee = 'SHORT_DISTANCE_TRANSPORTATION_FEE',
  LectureFee = 'LECTURE_FEE',
  MeetingServiceLive = 'MEETING_SERVICE_LIVE',
  Others = 'OTHERS'
}

export type BizExt = {
  __typename?: 'BizExt'
  rating?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['String']>
  hotelOrdering?: Maybe<Scalars['String']>
  mealOrdering?: Maybe<Scalars['String']>
  seatOrdering?: Maybe<Scalars['String']>
  ticketOrdering?: Maybe<Scalars['String']>
}

export type IPhoto = {
  __typename?: 'IPhoto'
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type HotelInfo = {
  __typename?: 'HotelInfo'
  id: Scalars['String']
  name: Scalars['String']
  type: Scalars['String']
  bizExt?: Maybe<BizExt>
  photos?: Maybe<Array<IPhoto>>
}

export type HotelSearchResult = {
  __typename?: 'HotelSearchResult'
  items: Array<HotelInfo>
  pager: PagerResult
}

export type HospitalPaginationResult = {
  __typename?: 'HospitalPaginationResult'
  items: Array<HospitalInfo>
  pager: PagerResult
}

export type PagerInput = {
  currentPage?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type ContentQueryInput = {
  sortField?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortType>
  status?: Maybe<ContentQueryStatus>
  pager?: Maybe<PagerInput>
  targetDoctorId?: Maybe<Scalars['String']>
}

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ContentQueryStatus {
  All = 'ALL',
  Unshare = 'UNSHARE',
  Unread = 'UNREAD',
  Shared = 'SHARED'
}

export type AccountBaseInfoInput = {
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
}

export type QcsfRegisterInput = {
  mobile: Scalars['String']
  cnName: Scalars['String']
  cityId?: Maybe<Scalars['String']>
  provinceId?: Maybe<Scalars['String']>
  hospitalId?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  certPictures?: Maybe<Array<Scalars['String']>>
  targetProvinceIds?: Maybe<Array<Scalars['String']>>
  targetCityIds?: Maybe<Array<Scalars['String']>>
}

export type MemberQueryInput = {
  search?: Maybe<Scalars['String']>
  pager?: Maybe<PagerInput>
}

export type RestaurantSearchQuery = {
  areaName?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  sortType?: Maybe<SortType>
  orderBy?: Maybe<Scalars['String']>
  keywords?: Maybe<Scalars['String']>
  radius?: Maybe<Scalars['String']>
}

export type RestaurantSearchInput = {
  query?: Maybe<RestaurantSearchQuery>
  pager?: Maybe<PagerInput>
}

export type HotelCreateInput = {
  id: Scalars['String']
  name: Scalars['String']
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
  bizExt?: Maybe<Scalars['String']>
}

export type MeetingOrderInput = {
  id?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  budgetId?: Maybe<Scalars['String']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<VendorType>
}

export type MeetingQuotationInput = {
  id?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  tax?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<VendorType>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type MeetingSettlementInput = {
  id?: Maybe<Scalars['Int']>
  orderId: Scalars['String']
  externalOrderId?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  tax?: Maybe<Scalars['Float']>
  num?: Maybe<Scalars['Float']>
  day?: Maybe<Scalars['Float']>
  note?: Maybe<Scalars['String']>
  tickets?: Maybe<Scalars['String']>
  attributes?: Maybe<Scalars['JSONObject']>
  vendorId?: Maybe<Scalars['String']>
  vendorType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['Timestamp']>
  gmtModified?: Maybe<Scalars['Timestamp']>
}

export type ConfirmBizOrderContentInput = {
  amount?: Maybe<Scalars['String']>
  receipts?: Maybe<Array<Scalars['String']>>
  invoices?: Maybe<Array<Scalars['String']>>
  peopleNum?: Maybe<Scalars['String']>
  drinkAmount?: Maybe<Scalars['String']>
}

export type MeetingCreateOrUpdateInput = {
  id?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name: Scalars['String']
  startTime: Scalars['Timestamp']
  endTime: Scalars['Timestamp']
  type: UpMeetingType
  insideAttenderNum: Scalars['Int']
  outsideAttenderNum: Scalars['Int']
  cityId: Scalars['String']
  hospitalIds?: Maybe<Array<Scalars['String']>>
  address?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  period: UpMeetingPeriod
  ownerId: Scalars['ID']
  hasSpeaker?: Maybe<Scalars['Boolean']>
}

export type MeetingQueryInput = {
  name?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<UpMeetingStatus>
  subStatus?: Maybe<Scalars['String']>
  pager?: Maybe<PagerInput>
}

export type BaseBmsMeetingSearchInput = {
  cityId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
}

export type BmsMeetingSearchInput = {
  cityId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<BmsMeetingStatus>
}

export type BmsMeetingSearchH5Input = {
  cityId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<UpMeetingStatus>
}

export type PaginatedBmsMeetingSearchInput = {
  query?: Maybe<BmsMeetingSearchInput>
  pager?: Maybe<PagerInput>
}

export type PaginatedBmsMeetingSearchH5Input = {
  query?: Maybe<BmsMeetingSearchH5Input>
  pager?: Maybe<PagerInput>
}

export type CarTravelCreatInput = {
  num: Scalars['String']
  carType: Scalars['String']
  type: Scalars['String']
  unit: Scalars['String']
  note: Scalars['String']
}

export type BmsMeetingHotelInput = {
  name: Scalars['String']
  address?: Maybe<Scalars['String']>
  position: Scalars['String']
  amapId: Scalars['String']
  city: Scalars['String']
}

export type BmsMeetingCreateOrUpdateInput = {
  id?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  name: Scalars['String']
  startTime: Scalars['Timestamp']
  endTime: Scalars['Timestamp']
  type: Scalars['String']
  insideAttenderNum: Scalars['Int']
  outsideAttenderNum: Scalars['Int']
  city: Scalars['String']
  address?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  period: UpMeetingPeriod
  ownerName: Scalars['String']
  hotel: BmsMeetingHotelInput
  travels: Array<CarTravelCreatInput>
}

export type AddAttendersInput = {
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  hospital?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  inviterName?: Maybe<Scalars['String']>
  inviterMobile?: Maybe<Scalars['String']>
}

export type ProductInfoInput = {
  id: Scalars['String']
  name: Scalars['String']
  isOtc?: Maybe<Scalars['Boolean']>
}

export type CreateStandardMeetingInput = {
  id?: Maybe<Scalars['String']>
  cityId: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  desc: Scalars['String']
  topic: Scalars['String']
  products: Array<ProductInfoInput>
  speaker: Scalars['String']
  pptUrl: Array<Scalars['String']>
  topicPictureUrls: Array<Scalars['String']>
  needAssistance: Scalars['Boolean']
  needQuiz: Scalars['Boolean']
  lectureFee: Scalars['Float']
  otherFee: Scalars['Float']
}

export type StandardMeetingSearchQuery = {
  keyword?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  meetingStatus?: Maybe<Array<StandardMeetingStatus>>
  productIds?: Maybe<Array<Scalars['String']>>
  meetingType?: Maybe<StandardMeetingType>
  serviceBizStatus?: Maybe<Array<StandardMeetingServiceBizStatus>>
}

export type StandardMeetingSearchInput = {
  region?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  projectType?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  meetingType?: Maybe<StandardMeetingType>
  serviceBizStatus?: Maybe<Array<StandardMeetingServiceBizStatus>>
}

export type PageQueryStandardMeetingInput = {
  query?: Maybe<StandardMeetingSearchQuery>
  pager?: Maybe<PagerInput>
}

export type PageSearchStandardMeetingInput = {
  query?: Maybe<StandardMeetingSearchInput>
  pager?: Maybe<PagerInput>
}

export type DoctorCompleteCertInfo = {
  certNo: Scalars['String']
  cardNo: Scalars['String']
  certImages: Array<Scalars['String']>
  bankName: Scalars['String']
}

export type HengruiMeetingSpeakerInput = {
  name: Scalars['String']
  accountId: Scalars['String']
  mobile: Scalars['String']
  topic?: Maybe<Scalars['String']>
  fee: Scalars['String']
  duration?: Maybe<Scalars['Int']>
  materials?: Maybe<Array<Scalars['String']>>
  data?: Maybe<Scalars['JSON']>
}

export type AddAttenderInput = {
  name: Scalars['String']
  mobile: Scalars['String']
  accountId?: Maybe<Scalars['String']>
  hospital?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  titleId?: Maybe<Scalars['String']>
  data?: Maybe<Scalars['JSON']>
}

export type SaveStandardMeetingCostInput = {
  amount: Scalars['String']
  type: Scalars['String']
  note?: Maybe<Scalars['String']>
}

export type CreateHengruiMeeting = {
  type: StandardMeetingType
  name?: Maybe<Scalars['String']>
  cityId: Scalars['String']
  address?: Maybe<Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  peopleNum: Scalars['Float']
  desc?: Maybe<Scalars['String']>
  projectType?: Maybe<Scalars['String']>
  speakers?: Maybe<Array<HengruiMeetingSpeakerInput>>
  hospital?: Maybe<Scalars['String']>
}

export type UpdateHengruiMeeting = {
  type: StandardMeetingType
  name?: Maybe<Scalars['String']>
  cityId: Scalars['String']
  address?: Maybe<Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  peopleNum: Scalars['Float']
  desc?: Maybe<Scalars['String']>
  projectType?: Maybe<Scalars['String']>
  speakers?: Maybe<Array<HengruiMeetingSpeakerInput>>
  hospital?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type SaleAccountInput = {
  cid: Scalars['String']
  uid: Scalars['String']
}

export type DocotorAccountInput = {
  cnName: Scalars['String']
  mobile: Scalars['String']
  hospitalId: Scalars['String']
  departmentId: Scalars['String']
  titleId: Scalars['String']
  staffId: Scalars['String']
}

export type BatchInfo = {
  sales: SaleAccountInput
  doctor: DocotorAccountInput
}

export type MeetingBudgetCreateInput = {
  id?: Maybe<Scalars['ID']>
  meetingId: Scalars['ID']
  type: UpMeetingBudgetType
  value: Scalars['Float']
  note?: Maybe<Scalars['String']>
  day?: Maybe<Scalars['Float']>
  num?: Maybe<Scalars['Int']>
}

export type MeetingScheduleInput = {
  id?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
  startTime: Scalars['Timestamp']
  endTime: Scalars['Timestamp']
  topic: Scalars['String']
  place?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type HotelSearchQuery = {
  keyword?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
}

export type HotelSearchInput = {
  query?: Maybe<HotelSearchQuery>
  pager?: Maybe<PagerInput>
}

export type HospitalSearchQuery = {
  cityId?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
}

export type HospitalPaginationRequest = {
  query?: Maybe<HospitalSearchQuery>
  pager?: Maybe<PagerInput>
}

export type Query = {
  __typename?: 'Query'
  meetingAttenderList: Array<MeetingUplatformAttender>
  insideAttenderList: MemberPaginatedResponse
  outsideAttenderList: MemberPaginatedResponse
  currentUser?: Maybe<AccountInfo>
  queryUser?: Maybe<AccountInfo>
  queryTargetRegionTree: Array<TargetRegionNodeInfo>
  queryTargetRegions: Array<TargetRegionNodeBaseInfo>
  queryDoctorAccountAttrConfigs: Array<AccountGroupInfo>
  bmsMeetingsH5: MeetingSearchResult
  bmsMeetings: MeetingSearchResult
  bmsMeetingStatus: Array<BmsMeetingStatusOption>
  bmsAttenders: Array<MeetingUplatformAttender>
  bmsQueryCarOfferPrice: Array<CarOfferPrice>
  bmsQueryCarOrder?: Maybe<BmsCarOrderDetail>
  bmsCarScheduleComplianceRisks?: Maybe<Scalars['JSON']>
  carOrder?: Maybe<CarOrderInfo>
  cities: Array<CityInfo>
  contentList?: Maybe<ContentListQueryResult>
  contentDetail?: Maybe<ContentInfo>
  contentActionList: Array<ContentActionInfo>
  hengruiImage_doctorHasCompeleteCert: Scalars['Boolean']
  hengruiImage_meetingList: PageQueryStandardMeetingResult
  hengruiImage_searchMeeting: PageQueryStandardMeetingResult
  hengruiImage_meetingDetail?: Maybe<StandardMeetingInfo>
  hengruiImage_doctors: MemberPaginatedResponse
  hengruiImage_myApprovals: PageQueryStandardMeetingResult
  hengruiImage_departments: GroupPaginationRes
  hengruiImage_titles: GroupPaginationRes
  hengruiImage_meetingCount: Scalars['JSON']
  hengruiImage_meetingTypes: Scalars['JSON']
  hengruiImage_approvalCount: Scalars['JSON']
  hengruiImage_statusMap: Array<HrMeetingStatusOption>
  hengruiImage_regions: Array<AccountGroupInfo>
  hengruiImage_attenderContract?: Maybe<Scalars['String']>
  hospitalList: Array<HospitalInfo>
  meetingDetail?: Maybe<MeetingInfo>
  meetingList?: Maybe<MeetingSearchResult>
  meetingListWithinQuotation?: Maybe<MeetingSearchResult>
  meetingStatusList: Array<UpMeetingStatusOption>
  meetingQuotationStatusList: Array<UpMeetingQuotationStatusOption>
  queryMeetingFiles: Scalars['JSON']
  queryMeetingCounts: Array<Scalars['Int']>
  meetingWaitForMyApproval?: Maybe<Array<MeetingInfo>>
  meetingApprovalFlow?: Maybe<ApproveInfo>
  meetingApprovalProcess: Array<MeetingUplatformProcess>
  meetingBudgetTypes: Array<MeetingBudgetTypeOption>
  meetingOrderList: Array<MeetingOrderInfo>
  relatedBizOrderList?: Maybe<Scalars['JSON']>
  quotationQueryByOrderId: Array<MeetingQuotationInfo>
  quotationQueryByBudgetId: Array<MeetingQuotationInfo>
  settlementQueryByOrderId: Array<MeetingSettlementInfo>
  queryMeetingLivePhotos: Scalars['JSON']
  signInList: Scalars['Boolean']
  signInLogs: Array<UpMeetingSignInInfo>
  pingguang_userInfo: AccountInfo
  pingguang_authLiveUrl?: Maybe<Scalars['String']>
  pingguang_meetingList: PageQueryStandardMeetingResult
  pingguang_meetingDetail: StandardMeetingInfo
  pingguang_lecturers: MemberPaginatedResponse
  pingguang_doctors: MemberPaginatedResponse
  pingguang_hospitals: HospitalPaginationRes
  pingguang_departments: GroupPaginationRes
  pingguang_titles: GroupPaginationRes
  pingguang_products: GroupPaginationRes
  pingguang_regions: GroupPaginationRes
  pingguang_pptList: Array<ResourceInfo>
  pingguang_approveList: Array<StandardMeetingInfo>
  salesPerformance: Array<SalesPerformanceData>
  salesPerformanceAll: Scalars['JSONObject']
  latestContents: Array<ContentInfo>
  relatedDoctors: MemberPaginatedResponse
  restaurantSearch: RestaurantSearchResult
  hotelSearch: HotelSearchResult
  carTypeSearch: Array<CarInfo>
  carServiceSearch: Array<CarServiceInfo>
  queryCarSupportedCities: Array<Scalars['String']>
  queryHospitals: HospitalPaginationResult
}

export type QueryMeetingAttenderListArgs = {
  type?: Maybe<AttenderType>
  meetingId: Scalars['String']
}

export type QueryInsideAttenderListArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryOutsideAttenderListArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryQueryUserArgs = {
  accountId: Scalars['String']
}

export type QueryQueryTargetRegionsArgs = {
  userType?: Maybe<QcsfUserType>
  pid?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
}

export type QueryQueryDoctorAccountAttrConfigsArgs = {
  search?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type QueryBmsMeetingsH5Args = {
  search?: Maybe<PaginatedBmsMeetingSearchH5Input>
}

export type QueryBmsMeetingsArgs = {
  search?: Maybe<PaginatedBmsMeetingSearchInput>
}

export type QueryBmsAttendersArgs = {
  name?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
  type: Scalars['String']
}

export type QueryBmsQueryCarOfferPriceArgs = {
  supplier: Scalars['String']
  cityName: Scalars['String']
  carType: Scalars['String']
  serviceName: Scalars['String']
}

export type QueryBmsQueryCarOrderArgs = {
  orderId: Scalars['String']
}

export type QueryBmsCarScheduleComplianceRisksArgs = {
  scheduleId: Scalars['String']
}

export type QueryCarOrderArgs = {
  orderId: Scalars['String']
}

export type QueryCitiesArgs = {
  level?: Maybe<Scalars['String']>
}

export type QueryContentListArgs = {
  query?: Maybe<ContentQueryInput>
}

export type QueryContentDetailArgs = {
  contentId: Scalars['String']
}

export type QueryContentActionListArgs = {
  contentId?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
}

export type QueryHengruiImage_DoctorHasCompeleteCertArgs = {
  mobile: Scalars['String']
}

export type QueryHengruiImage_MeetingListArgs = {
  filter?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryHengruiImage_SearchMeetingArgs = {
  pageQuery?: Maybe<PageSearchStandardMeetingInput>
}

export type QueryHengruiImage_MeetingDetailArgs = {
  meetingId: Scalars['String']
}

export type QueryHengruiImage_DoctorsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryHengruiImage_MyApprovalsArgs = {
  approveStatus?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryHengruiImage_DepartmentsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryHengruiImage_TitlesArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryHengruiImage_AttenderContractArgs = {
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type QueryHospitalListArgs = {
  city: Scalars['String']
}

export type QueryMeetingDetailArgs = {
  meetingId: Scalars['String']
}

export type QueryMeetingListArgs = {
  query?: Maybe<MeetingQueryInput>
  homeStatus?: Maybe<UpMeetingHomeStatus>
}

export type QueryMeetingListWithinQuotationArgs = {
  query?: Maybe<MeetingQueryInput>
  homeStatus?: Maybe<UpMeetingHomeStatus>
}

export type QueryQueryMeetingFilesArgs = {
  meetingId: Scalars['String']
}

export type QueryMeetingApprovalFlowArgs = {
  meetingId: Scalars['String']
}

export type QueryMeetingApprovalProcessArgs = {
  meetingId: Scalars['String']
}

export type QueryMeetingOrderListArgs = {
  budgetId: Scalars['String']
}

export type QueryRelatedBizOrderListArgs = {
  orderType: Scalars['String']
  orderId: Scalars['String']
}

export type QueryQuotationQueryByOrderIdArgs = {
  orderId: Scalars['String']
}

export type QueryQuotationQueryByBudgetIdArgs = {
  budgetId: Scalars['String']
}

export type QuerySettlementQueryByOrderIdArgs = {
  orderId: Scalars['String']
}

export type QueryQueryMeetingLivePhotosArgs = {
  scheduleId: Scalars['String']
  meetingId: Scalars['String']
}

export type QuerySignInLogsArgs = {
  meetingId: Scalars['String']
}

export type QueryPingguang_UserInfoArgs = {
  accountId?: Maybe<Scalars['String']>
}

export type QueryPingguang_AuthLiveUrlArgs = {
  meetingId: Scalars['String']
}

export type QueryPingguang_MeetingListArgs = {
  filter?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryPingguang_MeetingDetailArgs = {
  meetingId: Scalars['String']
}

export type QueryPingguang_LecturersArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_DoctorsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_HospitalsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_DepartmentsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_TitlesArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_ProductsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_RegionsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_ApproveListArgs = {
  submitted?: Maybe<Scalars['Boolean']>
}

export type QueryRelatedDoctorsArgs = {
  pager?: Maybe<PagerInput>
  keyword?: Maybe<Scalars['String']>
}

export type QueryRestaurantSearchArgs = {
  search?: Maybe<RestaurantSearchInput>
}

export type QueryHotelSearchArgs = {
  search?: Maybe<HotelSearchInput>
}

export type QueryCarTypeSearchArgs = {
  city?: Maybe<Scalars['String']>
}

export type QueryCarServiceSearchArgs = {
  carType: Scalars['String']
}

export type QueryQueryHospitalsArgs = {
  request?: Maybe<HospitalPaginationRequest>
}

/** doctor: 医生, propagator: 代表 */
export enum QcsfUserType {
  Doctor = 'DOCTOR',
  Propagator = 'PROPAGATOR'
}

/** u平台会议首页业务状态 */
export enum UpMeetingHomeStatus {
  UHomeApplication = 'U_HOME_APPLICATION',
  UHomeApproving = 'U_HOME_APPROVING',
  UHomeQuotation = 'U_HOME_QUOTATION',
  UHomeProcessing = 'U_HOME_PROCESSING',
  UHomeSettlement = 'U_HOME_SETTLEMENT',
  UHomeWaitformyapproval = 'U_HOME_WAITFORMYAPPROVAL',
  UHomeMyapproved = 'U_HOME_MYAPPROVED',
  UHomeAll = 'U_HOME_ALL'
}

export type Mutation = {
  __typename?: 'Mutation'
  login: Scalars['String']
  sendVerifyCode: Scalars['String']
  loginWithVerifyCode: Scalars['String']
  register: Scalars['String']
  simpleRegisterDoctor: Scalars['Boolean']
  logout: Scalars['Boolean']
  updateAccount: Scalars['Boolean']
  bmsLogin: BmsLoginResponse
  bmsCancelMeeting: Scalars['Boolean']
  bmsMeetingCreate: MeetingInfo
  bmsLoginWithVerifyCode: Scalars['String']
  bmsAddAttenderH5: Scalars['Boolean']
  addAttenders: Scalars['Boolean']
  bmsUploadSignedPaper: Scalars['Boolean']
  bmsUploadPO: Scalars['Boolean']
  bmsMeetingOrderBatchSync: Array<MeetingOrderInfo>
  bmsConfirmComplete: Scalars['Boolean']
  bmsQuotationBatchSave: Scalars['Boolean']
  bmsConfirmQuotations: Scalars['Boolean']
  createContent: Scalars['Boolean']
  updateContent: Scalars['Boolean']
  deleteContent: Scalars['Boolean']
  shareContent: Scalars['Boolean']
  commentContent: Scalars['Boolean']
  readContent: Scalars['Boolean']
  likeContent: Scalars['Boolean']
  dislikeContent: Scalars['Boolean']
  hengruiImage_sendCode: Scalars['String']
  hengruiImage_login: BmsLoginResponse
  hengruiImage_loginWithCode: Scalars['String']
  hengruiImage_completeDoctorCerts: Scalars['Boolean']
  hengruiImage_addTemporaryAttender: Scalars['Boolean']
  hengruiImage_updateMeeting?: Maybe<StandardMeetingInfo>
  hengruiImage_createMeeting?: Maybe<StandardMeetingInfo>
  hengruiImage_submitMeeting: Scalars['Boolean']
  hengruiImage_confirmMeeting: Scalars['Boolean']
  hengruiImage_retreatMeeting: Scalars['Boolean']
  hengruiImage_uploadSpeakerMaterials: Scalars['Boolean']
  hengruiImage_uploadAttenderTaxReceipts: Scalars['Boolean']
  hengruiImage_uploadAttenderPaymentVoucher: Scalars['Boolean']
  hengruiImage_uploadAttenderInvoices: Scalars['Boolean']
  hengruiImage_uploadSchedulePics: Scalars['Boolean']
  hengruiImage_uploadMaterials: Scalars['Boolean']
  hengruiImage_addDoctors: Scalars['Boolean']
  hengruiImage_removeDoctors: Scalars['Boolean']
  hengruiImage_removeSpeakers: Scalars['Boolean']
  hengruiImage_addCost: Scalars['Boolean']
  hengruiImage_addCheckReport: Scalars['Boolean']
  hengruiImage_setDoctorBeenInvited?: Maybe<Scalars['Boolean']>
  hengruiImage_generateContract?: Maybe<Scalars['String']>
  hengruiImage_editDoctor: Scalars['Boolean']
  hengruiImage_markHRMeetingPaymentFinished: Scalars['Boolean']
  hengruiImage_getSettlementZipUrl?: Maybe<Scalars['String']>
  batchSyncPropagatorAndDoctors: Scalars['Boolean']
  meetingCreate?: Maybe<MeetingInfo>
  meetingEdit?: Maybe<MeetingInfo>
  attenderBatchAdd: Array<MeetingUplatformAttender>
  attenderRemove?: Maybe<Scalars['String']>
  submitFiles: Scalars['Boolean']
  meetingSubmit?: Maybe<Scalars['String']>
  meetingApprovalNotify?: Maybe<Scalars['Boolean']>
  meetingConfirm?: Maybe<Scalars['Boolean']>
  meetingReject?: Maybe<Scalars['Boolean']>
  meetingRetreat?: Maybe<Scalars['Boolean']>
  meetingWithdraw?: Maybe<Scalars['Boolean']>
  meetingBudgetCreate: MeetingUplatformBudget
  meetingBudgetUpdate: Scalars['String']
  meetingBudgetBatchSync: Array<MeetingUplatformBudget>
  meetingOrderBatchSync: Array<MeetingOrderInfo>
  meetingOrderSave: MeetingOrderInfo
  meetingOrderDelete: Scalars['Boolean']
  meetingOrderAcquireQuotation: Scalars['Boolean']
  meetingOrderRetreatQuotation: Scalars['Boolean']
  meetingOrderSubmitQuotation: Scalars['Boolean']
  meetingOrderSubmitQuotationOLd: Scalars['Boolean']
  dangerAddBizOrderConfirm: Scalars['Boolean']
  bizOrderConfirm: Scalars['Boolean']
  quotationSave: Scalars['Boolean']
  quotationDelete: Scalars['Boolean']
  quotationBatchSave: Scalars['Boolean']
  confirmQuotations: Scalars['Boolean']
  procurementerApproveQuotation: Scalars['Boolean']
  meetingScheduleCreate: MeetingUplatformSchedule
  meetingScheduleUpdate: MeetingUplatformSchedule
  meetingScheduleDelete: Scalars['Boolean']
  meetingScheduleBatchSync: Array<MeetingUplatformSchedule>
  settlementSave: Scalars['Boolean']
  settlementDelete: Scalars['Boolean']
  submitSettlement: Scalars['Boolean']
  submitLivePhotos: Scalars['Boolean']
  settlementBatchSave: Scalars['Boolean']
  pingguang_sendCode: Scalars['String']
  pingguang_login: Scalars['String']
  pingguang_lectureLogin: Scalars['String']
  pingguang_attenderLogin: Scalars['String']
  pingguang_updateMeeting?: Maybe<StandardMeetingInfo>
  pingguang_createMeeting?: Maybe<StandardMeetingInfo>
  pingguang_approveMeeting: Scalars['Boolean']
  pingguang_rejectMeeting: Scalars['Boolean']
  sendAuthMessage: Scalars['Boolean']
}

export type MutationLoginArgs = {
  password: Scalars['String']
  mobile: Scalars['String']
}

export type MutationSendVerifyCodeArgs = {
  userType: QcsfUserType
  stage: QcsfSendVerifyCodeStage
  mobile: Scalars['String']
}

export type MutationLoginWithVerifyCodeArgs = {
  userType: QcsfUserType
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationRegisterArgs = {
  inviterId?: Maybe<Scalars['String']>
  userType: QcsfUserType
  userInfo: QcsfRegisterInput
}

export type MutationSimpleRegisterDoctorArgs = {
  userInfo: QcsfRegisterInput
}

export type MutationUpdateAccountArgs = {
  account: AccountBaseInfoInput
}

export type MutationBmsLoginArgs = {
  password: Scalars['String']
  mobile: Scalars['String']
}

export type MutationBmsCancelMeetingArgs = {
  meetingId: Scalars['String']
}

export type MutationBmsMeetingCreateArgs = {
  meetingInfo: BmsMeetingCreateOrUpdateInput
}

export type MutationBmsLoginWithVerifyCodeArgs = {
  sessionId: Scalars['String']
  code: Scalars['String']
  mobile: Scalars['String']
}

export type MutationBmsAddAttenderH5Args = {
  attender: AddAttendersInput
  meetingId: Scalars['String']
}

export type MutationAddAttendersArgs = {
  attenders: Array<AddAttendersInput>
  meetingId: Scalars['String']
}

export type MutationBmsUploadSignedPaperArgs = {
  url: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationBmsUploadPoArgs = {
  url: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationBmsMeetingOrderBatchSyncArgs = {
  orders: Array<CarTravelCreatInput>
  budgetId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationBmsConfirmCompleteArgs = {
  meetingId: Scalars['String']
}

export type MutationBmsQuotationBatchSaveArgs = {
  quotations: Array<MeetingQuotationInput>
  meetingId: Scalars['String']
}

export type MutationBmsConfirmQuotationsArgs = {
  meetingId: Scalars['String']
}

export type MutationDeleteContentArgs = {
  id: Scalars['String']
}

export type MutationShareContentArgs = {
  doctorId: Scalars['String']
  contentId: Scalars['String']
}

export type MutationCommentContentArgs = {
  openId?: Maybe<Scalars['String']>
  commentorId?: Maybe<Scalars['String']>
  comment: Scalars['String']
  sharerId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationReadContentArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
  sharerId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationLikeContentArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
  sharerId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationDislikeContentArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationHengruiImage_SendCodeArgs = {
  mobile: Scalars['String']
}

export type MutationHengruiImage_LoginArgs = {
  pwd: Scalars['String']
  mobile: Scalars['String']
}

export type MutationHengruiImage_LoginWithCodeArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationHengruiImage_CompleteDoctorCertsArgs = {
  doctorInfo: DoctorCompleteCertInfo
  doctorAccountId: Scalars['String']
}

export type MutationHengruiImage_AddTemporaryAttenderArgs = {
  attender: AddAttenderInput
}

export type MutationHengruiImage_UpdateMeetingArgs = {
  meetingInfo: UpdateHengruiMeeting
}

export type MutationHengruiImage_CreateMeetingArgs = {
  meetingInfo: CreateHengruiMeeting
}

export type MutationHengruiImage_SubmitMeetingArgs = {
  meetingId: Scalars['String']
}

export type MutationHengruiImage_ConfirmMeetingArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RetreatMeetingArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadSpeakerMaterialsArgs = {
  materials: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadAttenderTaxReceiptsArgs = {
  urls: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadAttenderPaymentVoucherArgs = {
  urls: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadAttenderInvoicesArgs = {
  urls: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadSchedulePicsArgs = {
  schedulePicUrls: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadMaterialsArgs = {
  materialUrls: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddDoctorsArgs = {
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
  doctorInfo: HengruiMeetingSpeakerInput
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RemoveDoctorsArgs = {
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RemoveSpeakersArgs = {
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddCostArgs = {
  costs: Array<SaveStandardMeetingCostInput>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddCheckReportArgs = {
  urls: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_SetDoctorBeenInvitedArgs = {
  doctorType: StandardMeetingTypeAttenderType
  meetingId: Scalars['String']
  doctorId: Scalars['String']
}

export type MutationHengruiImage_GenerateContractArgs = {
  inviterId: Scalars['String']
  meetingId: Scalars['String']
  signerId: Scalars['String']
}

export type MutationHengruiImage_EditDoctorArgs = {
  doctor: AddAttenderInput
}

export type MutationHengruiImage_MarkHrMeetingPaymentFinishedArgs = {
  meetingId: Scalars['String']
}

export type MutationHengruiImage_GetSettlementZipUrlArgs = {
  meetingId: Scalars['String']
}

export type MutationBatchSyncPropagatorAndDoctorsArgs = {
  infos: Array<BatchInfo>
  companyId: Scalars['String']
}

export type MutationMeetingCreateArgs = {
  meetingInfo: MeetingCreateOrUpdateInput
}

export type MutationMeetingEditArgs = {
  meetingInfo: MeetingCreateOrUpdateInput
}

export type MutationAttenderBatchAddArgs = {
  type: AttenderType
  accountIds: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationAttenderRemoveArgs = {
  attenderId: Scalars['String']
}

export type MutationSubmitFilesArgs = {
  files: Scalars['JSON']
  pptId: Scalars['String']
  pptName: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationMeetingSubmitArgs = {
  meetingId: Scalars['String']
}

export type MutationMeetingApprovalNotifyArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingConfirmArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingRejectArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingRetreatArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingWithdrawArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingBudgetCreateArgs = {
  budget: MeetingBudgetCreateInput
}

export type MutationMeetingBudgetBatchSyncArgs = {
  budgets: Array<MeetingBudgetCreateInput>
  meetingId: Scalars['String']
}

export type MutationMeetingOrderBatchSyncArgs = {
  orders: Array<MeetingOrderInput>
  budgetId: Scalars['String']
}

export type MutationMeetingOrderSaveArgs = {
  order: MeetingOrderInput
}

export type MutationMeetingOrderDeleteArgs = {
  orderId: Scalars['String']
}

export type MutationMeetingOrderAcquireQuotationArgs = {
  meetingId: Scalars['String']
}

export type MutationMeetingOrderRetreatQuotationArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingOrderSubmitQuotationArgs = {
  meetingId: Scalars['String']
}

export type MutationMeetingOrderSubmitQuotationOLdArgs = {
  meetingId: Scalars['String']
}

export type MutationDangerAddBizOrderConfirmArgs = {
  type: UpMeetingBudgetType
  orderId: Scalars['String']
}

export type MutationBizOrderConfirmArgs = {
  orderType: UpMeetingBudgetType
  content: ConfirmBizOrderContentInput
  orderId: Scalars['String']
}

export type MutationQuotationSaveArgs = {
  quotation: MeetingQuotationInput
}

export type MutationQuotationDeleteArgs = {
  quotationId: Scalars['String']
}

export type MutationQuotationBatchSaveArgs = {
  quotations: Array<MeetingQuotationInput>
  meetingId: Scalars['String']
}

export type MutationConfirmQuotationsArgs = {
  meetingId: Scalars['String']
}

export type MutationProcurementerApproveQuotationArgs = {
  reason?: Maybe<Scalars['String']>
  result?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingScheduleCreateArgs = {
  schedule: MeetingScheduleInput
}

export type MutationMeetingScheduleUpdateArgs = {
  schedule: MeetingScheduleInput
}

export type MutationMeetingScheduleDeleteArgs = {
  scheduleId: Scalars['String']
}

export type MutationMeetingScheduleBatchSyncArgs = {
  schedules: Array<MeetingScheduleInput>
  meetingId: Scalars['String']
}

export type MutationSettlementSaveArgs = {
  settlement: MeetingSettlementInput
}

export type MutationSettlementDeleteArgs = {
  settlementId: Scalars['String']
}

export type MutationSubmitSettlementArgs = {
  meetingId: Scalars['String']
}

export type MutationSubmitLivePhotosArgs = {
  photos?: Maybe<Scalars['JSON']>
  scheduleId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationSettlementBatchSaveArgs = {
  settlements: Array<MeetingSettlementInput>
  meetingId: Scalars['String']
}

export type MutationPingguang_SendCodeArgs = {
  stage: QcsfSendVerifyCodeStage
  mobile: Scalars['String']
}

export type MutationPingguang_LoginArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationPingguang_LectureLoginArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationPingguang_AttenderLoginArgs = {
  titleId: Scalars['String']
  departmentId: Scalars['String']
  hospitalId: Scalars['String']
  name: Scalars['String']
  mobile: Scalars['String']
}

export type MutationPingguang_UpdateMeetingArgs = {
  meeting: CreateStandardMeetingInput
}

export type MutationPingguang_CreateMeetingArgs = {
  meeting: CreateStandardMeetingInput
}

export type MutationPingguang_ApproveMeetingArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationPingguang_RejectMeetingArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationSendAuthMessageArgs = {
  doctorId: Scalars['String']
}

/** login: 登录, register: 注册 */
export enum QcsfSendVerifyCodeStage {
  Login = 'LOGIN',
  Register = 'REGISTER'
}

export type CarServiceOfferPriceQueryVariables = {
  supplier: Scalars['String']
  serviceName: Scalars['String']
  carType: Scalars['String']
  cityName: Scalars['String']
}

export type CarServiceOfferPriceQuery = { __typename?: 'Query' } & {
  price: Array<
    { __typename?: 'CarOfferPrice' } & Pick<
      CarOfferPrice,
      'id' | 'price' | 'supplier' | 'carType' | 'serviceType'
    >
  >
}

export type CarScheduleComplianceRisksQueryVariables = {
  scheduleId: Scalars['String']
}

export type CarScheduleComplianceRisksQuery = { __typename?: 'Query' } & {
  risks: Query['bmsCarScheduleComplianceRisks']
}

export type CityListQueryVariables = {}

export type CityListQuery = { __typename?: 'Query' } & {
  cities: Array<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name' | 'id'>>
}

export type MeetingBaseInfoFragment = { __typename?: 'MeetingInfo' } & Pick<
  MeetingInfo,
  | 'id'
  | 'code'
  | 'name'
  | 'status'
  | 'subStatus'
  | 'hotel'
  | 'department'
  | 'outsideAttenderNum'
  | 'insideAttenderNum'
  | 'startTime'
  | 'endTime'
  | 'bmsMarkedPaper'
  | 'bmsPOBill'
> & { ownerName: MeetingInfo['ownerId']; type: MeetingInfo['bmsType'] } & {
    city?: Maybe<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name'>>
    bmsMeetingHotel?: Maybe<
      { __typename?: 'BmsMeetingHotel' } & Pick<
        BmsMeetingHotel,
        'name' | 'position' | 'address' | 'city' | 'amapId'
      >
    >
  }

export type MeetingListQueryVariables = {
  query?: Maybe<PaginatedBmsMeetingSearchInput>
}

export type MeetingListQuery = { __typename?: 'Query' } & {
  meetings: { __typename?: 'MeetingSearchResult' } & {
    items: Array<
      { __typename?: 'MeetingInfo' } & {
        bizStatus?: Maybe<
          { __typename?: 'BizStatusInfo' } & Pick<
            BizStatusInfo,
            'text' | 'color'
          >
        >
        budgets: { __typename?: 'MeetingBudgetsEntry' } & {
          items: Array<
            { __typename?: 'MeetingUplatformBudget' } & Pick<
              MeetingUplatformBudget,
              'type'
            > & {
                orders: Array<
                  { __typename?: 'MeetingOrderInfo' } & Pick<
                    MeetingOrderInfo,
                    'type' | 'num' | 'attributes'
                  >
                >
              }
          >
        }
      } & MeetingBaseInfoFragment
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'pageSize' | 'currentPage' | 'total'
    >
  }
}

export type MeetingAbnormalListQueryVariables = {
  query?: Maybe<PaginatedBmsMeetingSearchInput>
}

export type MeetingAbnormalListQuery = { __typename?: 'Query' } & {
  meetings: { __typename?: 'MeetingSearchResult' } & {
    items: Array<
      { __typename?: 'MeetingInfo' } & {
        abnormalCount: MeetingInfo['bmsMeetingAbnormalCount']
      } & {
        bizStatus?: Maybe<
          { __typename?: 'BizStatusInfo' } & Pick<
            BizStatusInfo,
            'text' | 'color'
          >
        >
        budgets: { __typename?: 'MeetingBudgetsEntry' } & {
          items: Array<
            { __typename?: 'MeetingUplatformBudget' } & Pick<
              MeetingUplatformBudget,
              'type'
            > & {
                orders: Array<
                  { __typename?: 'MeetingOrderInfo' } & Pick<
                    MeetingOrderInfo,
                    'type' | 'num' | 'attributes'
                  >
                >
              }
          >
        }
      } & MeetingBaseInfoFragment
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'pageSize' | 'currentPage' | 'total'
    >
  }
}

export type MeetingDetailQueryVariables = {
  meetingId: Scalars['String']
}

export type MeetingDetailQuery = { __typename?: 'Query' } & {
  meetingDetail?: Maybe<
    { __typename?: 'MeetingInfo' } & Pick<
      MeetingInfo,
      'ownerId' | 'carOrders'
    > & {
        bizStatus?: Maybe<
          { __typename?: 'BizStatusInfo' } & Pick<
            BizStatusInfo,
            'text' | 'color'
          >
        >
        attenders: Array<
          { __typename?: 'MeetingUplatformAttender' } & Pick<
            MeetingUplatformAttender,
            'type' | 'attributes'
          >
        >
        budgets: { __typename?: 'MeetingBudgetsEntry' } & {
          items: Array<
            { __typename?: 'MeetingUplatformBudget' } & Pick<
              MeetingUplatformBudget,
              'id' | 'type'
            > & {
                orders: Array<
                  { __typename?: 'MeetingOrderInfo' } & Pick<
                    MeetingOrderInfo,
                    'id' | 'type' | 'num' | 'attributes'
                  > & {
                      quotations: Array<
                        { __typename?: 'MeetingQuotationInfo' } & Pick<
                          MeetingQuotationInfo,
                          | 'orderId'
                          | 'id'
                          | 'vendorId'
                          | 'num'
                          | 'total'
                          | 'price'
                          | 'tax'
                          | 'attributes'
                        >
                      >
                    }
                >
              }
          >
        }
      } & MeetingBaseInfoFragment
  >
}

export type CreatMeetingMutationVariables = {
  meetingInfo: BmsMeetingCreateOrUpdateInput
}

export type CreatMeetingMutation = { __typename?: 'Mutation' } & {
  bmsMeetingCreate: { __typename?: 'MeetingInfo' } & Pick<
    MeetingInfo,
    'id' | 'name'
  >
}

export type CancelMeetingMutationVariables = {
  meetingId: Scalars['String']
}

export type CancelMeetingMutation = { __typename?: 'Mutation' } & {
  success: Mutation['bmsCancelMeeting']
}

export type AddAttendersMutationVariables = {
  attenders: Array<AddAttendersInput>
  meetingId: Scalars['String']
}

export type AddAttendersMutation = { __typename?: 'Mutation' } & {
  success: Mutation['addAttenders']
}

export type BatchSaveQuotationMutationVariables = {
  meetingId: Scalars['String']
  quotations: Array<MeetingQuotationInput>
}

export type BatchSaveQuotationMutation = { __typename?: 'Mutation' } & {
  quotationBatchSave: Mutation['bmsQuotationBatchSave']
}

export type RetreatQuotationMutationVariables = {
  meetingId: Scalars['String']
  reason?: Maybe<Scalars['String']>
}

export type RetreatQuotationMutation = { __typename?: 'Mutation' } & {
  success: Mutation['meetingOrderRetreatQuotation']
}

export type ConfirmQuotationsMutationVariables = {
  meetingId: Scalars['String']
}

export type ConfirmQuotationsMutation = { __typename?: 'Mutation' } & {
  success: Mutation['bmsConfirmQuotations']
}

export type MeetingStatusMapQueryVariables = {}

export type MeetingStatusMapQuery = { __typename?: 'Query' } & {
  statusMap: Array<
    { __typename?: 'BmsMeetingStatusOption' } & Pick<
      BmsMeetingStatusOption,
      'value' | 'label'
    >
  >
}

export type BmsCarOrderDetailQueryVariables = {
  carOrderId: Scalars['String']
}

export type BmsCarOrderDetailQuery = { __typename?: 'Query' } & {
  detail?: Maybe<
    { __typename?: 'BmsCarOrderDetail' } & Pick<BmsCarOrderDetail, 'order'> & {
        meeting?: Maybe<
          { __typename?: 'MeetingInfo' } & MeetingBaseInfoFragment
        >
      }
  >
}

export type OrderBatchSyncMutationVariables = {
  meetingId: Scalars['String']
  budgetId: Scalars['String']
  orders: Array<CarTravelCreatInput>
}

export type OrderBatchSyncMutation = { __typename?: 'Mutation' } & {
  meetingOrderBatchSync: Array<
    { __typename?: 'MeetingOrderInfo' } & Pick<MeetingOrderInfo, 'id'>
  >
}

export type UploadPoMutationVariables = {
  meetingId: Scalars['String']
  url: Scalars['String']
}

export type UploadPoMutation = { __typename?: 'Mutation' } & {
  uploadRes: Mutation['bmsUploadPO']
}

export type UploadSignedPaperMutationVariables = {
  meetingId: Scalars['String']
  url: Scalars['String']
}

export type UploadSignedPaperMutation = { __typename?: 'Mutation' } & {
  uploadRes: Mutation['bmsUploadSignedPaper']
}

export type ComfirmMeetingToFinishedMutationVariables = {
  meetingId: Scalars['String']
}

export type ComfirmMeetingToFinishedMutation = { __typename?: 'Mutation' } & {
  result: Mutation['bmsConfirmComplete']
}

export type AccountFieldFragment = { __typename?: 'AccountInfo' } & Pick<
  AccountInfo,
  'accountId' | 'companyId'
> & { name: AccountInfo['cnName']; mobile: AccountInfo['companyMobile'] }

export type CurrentUserQueryVariables = {}

export type CurrentUserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'AccountInfo' } & {
      roles: Array<
        { __typename?: 'RoleInfo' } & Pick<
          RoleInfo,
          'id' | 'name' | 'type' | 'enable'
        >
      >
    } & AccountFieldFragment
  >
}

export type UserInfoQueryVariables = {
  accountId: Scalars['String']
}

export type UserInfoQuery = { __typename?: 'Query' } & {
  user?: Maybe<{ __typename?: 'AccountInfo' } & AccountFieldFragment>
}

export type UserLoginMutationVariables = {
  password: Scalars['String']
  mobile: Scalars['String']
}

export type UserLoginMutation = { __typename?: 'Mutation' } & {
  bmsLogin: { __typename?: 'BmsLoginResponse' } & Pick<
    BmsLoginResponse,
    'token'
  > & {
      account: { __typename?: 'AccountInfo' } & {
        roles: Array<
          { __typename?: 'RoleInfo' } & Pick<
            RoleInfo,
            'id' | 'name' | 'type' | 'enable'
          >
        >
      } & AccountFieldFragment
    }
}

export const MeetingBaseInfoFragmentDoc = gql`
  fragment meetingBaseInfo on MeetingInfo {
    id
    code
    name
    status
    subStatus
    hotel
    city {
      name
    }
    department
    ownerName: ownerId
    outsideAttenderNum
    insideAttenderNum
    startTime
    endTime
    type: bmsType
    bmsMeetingHotel {
      name
      position
      address
      city
      amapId
    }
    bmsMarkedPaper
    bmsPOBill
  }
`
export const AccountFieldFragmentDoc = gql`
  fragment accountField on AccountInfo {
    name: cnName
    accountId
    companyId
    mobile: companyMobile
  }
`
export const CarServiceOfferPriceDocument = gql`
  query CarServiceOfferPrice(
    $supplier: String!
    $serviceName: String!
    $carType: String!
    $cityName: String!
  ) {
    price: bmsQueryCarOfferPrice(
      supplier: $supplier
      cityName: $cityName
      carType: $carType
      serviceName: $serviceName
    ) {
      id
      price
      supplier
      carType
      serviceType
    }
  }
`

export function useCarServiceOfferPriceQuery(
  options: Omit<
    Urql.UseQueryArgs<CarServiceOfferPriceQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CarServiceOfferPriceQuery>({
    query: CarServiceOfferPriceDocument,
    ...options
  })
}
export const CarScheduleComplianceRisksDocument = gql`
  query CarScheduleComplianceRisks($scheduleId: String!) {
    risks: bmsCarScheduleComplianceRisks(scheduleId: $scheduleId)
  }
`

export function useCarScheduleComplianceRisksQuery(
  options: Omit<
    Urql.UseQueryArgs<CarScheduleComplianceRisksQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CarScheduleComplianceRisksQuery>({
    query: CarScheduleComplianceRisksDocument,
    ...options
  })
}
export const CityListDocument = gql`
  query CityList {
    cities(level: "2") {
      name
      id
    }
  }
`

export function useCityListQuery(
  options: Omit<Urql.UseQueryArgs<CityListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CityListQuery>({ query: CityListDocument, ...options })
}
export const MeetingListDocument = gql`
  query MeetingList($query: PaginatedBmsMeetingSearchInput) {
    meetings: bmsMeetings(search: $query) {
      items {
        ...meetingBaseInfo
        bizStatus: bmsBizStatus {
          text
          color
        }
        budgets {
          items {
            type
            orders {
              type
              num
              attributes
            }
          }
        }
      }
      pager {
        pageSize
        currentPage
        total
      }
    }
  }
  ${MeetingBaseInfoFragmentDoc}
`

export function useMeetingListQuery(
  options: Omit<Urql.UseQueryArgs<MeetingListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<MeetingListQuery>({
    query: MeetingListDocument,
    ...options
  })
}
export const MeetingAbnormalListDocument = gql`
  query MeetingAbnormalList($query: PaginatedBmsMeetingSearchInput) {
    meetings: bmsMeetings(search: $query) {
      items {
        ...meetingBaseInfo
        bizStatus: bmsBizStatus {
          text
          color
        }
        budgets {
          items {
            type
            orders {
              type
              num
              attributes
            }
          }
        }
        abnormalCount: bmsMeetingAbnormalCount
      }
      pager {
        pageSize
        currentPage
        total
      }
    }
  }
  ${MeetingBaseInfoFragmentDoc}
`

export function useMeetingAbnormalListQuery(
  options: Omit<
    Urql.UseQueryArgs<MeetingAbnormalListQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<MeetingAbnormalListQuery>({
    query: MeetingAbnormalListDocument,
    ...options
  })
}
export const MeetingDetailDocument = gql`
  query MeetingDetail($meetingId: String!) {
    meetingDetail(meetingId: $meetingId) {
      ...meetingBaseInfo
      bizStatus: bmsBizStatus {
        text
        color
      }
      attenders {
        type
        attributes
      }
      ownerId
      carOrders
      budgets {
        items {
          id
          type
          orders {
            id
            type
            num
            attributes
            quotations {
              orderId
              id
              vendorId
              num
              total
              price
              tax
              attributes
            }
          }
        }
      }
    }
  }
  ${MeetingBaseInfoFragmentDoc}
`

export function useMeetingDetailQuery(
  options: Omit<Urql.UseQueryArgs<MeetingDetailQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<MeetingDetailQuery>({
    query: MeetingDetailDocument,
    ...options
  })
}
export const CreatMeetingDocument = gql`
  mutation CreatMeeting($meetingInfo: BmsMeetingCreateOrUpdateInput!) {
    bmsMeetingCreate(meetingInfo: $meetingInfo) {
      id
      name
    }
  }
`

export function useCreatMeetingMutation() {
  return Urql.useMutation<CreatMeetingMutation, CreatMeetingMutationVariables>(
    CreatMeetingDocument
  )
}
export const CancelMeetingDocument = gql`
  mutation CancelMeeting($meetingId: String!) {
    success: bmsCancelMeeting(meetingId: $meetingId)
  }
`

export function useCancelMeetingMutation() {
  return Urql.useMutation<
    CancelMeetingMutation,
    CancelMeetingMutationVariables
  >(CancelMeetingDocument)
}
export const AddAttendersDocument = gql`
  mutation AddAttenders(
    $attenders: [AddAttendersInput!]!
    $meetingId: String!
  ) {
    success: addAttenders(meetingId: $meetingId, attenders: $attenders)
  }
`

export function useAddAttendersMutation() {
  return Urql.useMutation<AddAttendersMutation, AddAttendersMutationVariables>(
    AddAttendersDocument
  )
}
export const BatchSaveQuotationDocument = gql`
  mutation BatchSaveQuotation(
    $meetingId: String!
    $quotations: [MeetingQuotationInput!]!
  ) {
    quotationBatchSave: bmsQuotationBatchSave(
      meetingId: $meetingId
      quotations: $quotations
    )
  }
`

export function useBatchSaveQuotationMutation() {
  return Urql.useMutation<
    BatchSaveQuotationMutation,
    BatchSaveQuotationMutationVariables
  >(BatchSaveQuotationDocument)
}
export const RetreatQuotationDocument = gql`
  mutation RetreatQuotation($meetingId: String!, $reason: String) {
    success: meetingOrderRetreatQuotation(
      meetingId: $meetingId
      reason: $reason
    )
  }
`

export function useRetreatQuotationMutation() {
  return Urql.useMutation<
    RetreatQuotationMutation,
    RetreatQuotationMutationVariables
  >(RetreatQuotationDocument)
}
export const ConfirmQuotationsDocument = gql`
  mutation ConfirmQuotations($meetingId: String!) {
    success: bmsConfirmQuotations(meetingId: $meetingId)
  }
`

export function useConfirmQuotationsMutation() {
  return Urql.useMutation<
    ConfirmQuotationsMutation,
    ConfirmQuotationsMutationVariables
  >(ConfirmQuotationsDocument)
}
export const MeetingStatusMapDocument = gql`
  query MeetingStatusMap {
    statusMap: bmsMeetingStatus {
      value
      label
    }
  }
`

export function useMeetingStatusMapQuery(
  options: Omit<Urql.UseQueryArgs<MeetingStatusMapQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<MeetingStatusMapQuery>({
    query: MeetingStatusMapDocument,
    ...options
  })
}
export const BmsCarOrderDetailDocument = gql`
  query BmsCarOrderDetail($carOrderId: String!) {
    detail: bmsQueryCarOrder(orderId: $carOrderId) {
      meeting {
        ...meetingBaseInfo
      }
      order
    }
  }
  ${MeetingBaseInfoFragmentDoc}
`

export function useBmsCarOrderDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<BmsCarOrderDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<BmsCarOrderDetailQuery>({
    query: BmsCarOrderDetailDocument,
    ...options
  })
}
export const OrderBatchSyncDocument = gql`
  mutation OrderBatchSync(
    $meetingId: String!
    $budgetId: String!
    $orders: [CarTravelCreatInput!]!
  ) {
    meetingOrderBatchSync: bmsMeetingOrderBatchSync(
      meetingId: $meetingId
      budgetId: $budgetId
      orders: $orders
    ) {
      id
    }
  }
`

export function useOrderBatchSyncMutation() {
  return Urql.useMutation<
    OrderBatchSyncMutation,
    OrderBatchSyncMutationVariables
  >(OrderBatchSyncDocument)
}
export const UploadPoDocument = gql`
  mutation UploadPO($meetingId: String!, $url: String!) {
    uploadRes: bmsUploadPO(meetingId: $meetingId, url: $url)
  }
`

export function useUploadPoMutation() {
  return Urql.useMutation<UploadPoMutation, UploadPoMutationVariables>(
    UploadPoDocument
  )
}
export const UploadSignedPaperDocument = gql`
  mutation UploadSignedPaper($meetingId: String!, $url: String!) {
    uploadRes: bmsUploadSignedPaper(meetingId: $meetingId, url: $url)
  }
`

export function useUploadSignedPaperMutation() {
  return Urql.useMutation<
    UploadSignedPaperMutation,
    UploadSignedPaperMutationVariables
  >(UploadSignedPaperDocument)
}
export const ComfirmMeetingToFinishedDocument = gql`
  mutation ComfirmMeetingToFinished($meetingId: String!) {
    result: bmsConfirmComplete(meetingId: $meetingId)
  }
`

export function useComfirmMeetingToFinishedMutation() {
  return Urql.useMutation<
    ComfirmMeetingToFinishedMutation,
    ComfirmMeetingToFinishedMutationVariables
  >(ComfirmMeetingToFinishedDocument)
}
export const CurrentUserDocument = gql`
  query CurrentUser {
    user: currentUser {
      ...accountField
      roles {
        id
        name
        type
        enable
      }
    }
  }
  ${AccountFieldFragmentDoc}
`

export function useCurrentUserQuery(
  options: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CurrentUserQuery>({
    query: CurrentUserDocument,
    ...options
  })
}
export const UserInfoDocument = gql`
  query UserInfo($accountId: String!) {
    user: queryUser(accountId: $accountId) {
      ...accountField
    }
  }
  ${AccountFieldFragmentDoc}
`

export function useUserInfoQuery(
  options: Omit<Urql.UseQueryArgs<UserInfoQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<UserInfoQuery>({ query: UserInfoDocument, ...options })
}
export const UserLoginDocument = gql`
  mutation UserLogin($password: String!, $mobile: String!) {
    bmsLogin(password: $password, mobile: $mobile) {
      token
      account {
        ...accountField
        roles {
          id
          name
          type
          enable
        }
      }
    }
  }
  ${AccountFieldFragmentDoc}
`

export function useUserLoginMutation() {
  return Urql.useMutation<UserLoginMutation, UserLoginMutationVariables>(
    UserLoginDocument
  )
}
