import React from 'react'
import { Descriptions, Table, Row, Col, Card } from 'antd'

// Import ECharts.js modules manually to reduce bundle size
// import the core library.
import ReactEchartsCore from 'echarts-for-react/lib/core'
// then import echarts modules those you have used manually.
import echarts, { EChartOption } from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'

export enum ELegendType {
  vertical = 'vertical',
  horizontal = 'horizontal'
}
export enum ETriggerType {
  item = 'item',
  none = 'none',
  axis = 'axis'
}
export enum EAxisPointer {
  cross = 'cross',
  none = 'none',
  line = 'line',
  shadow = 'shadow'
}
export enum EAxisType {
  category = 'category',
  value = 'value',
  log = 'log',
  time = 'time'
}
export enum EAxisPointerType {
  shadow = 'shadow',
  line = 'line',
  none = 'none'
}
export type CarTypeDescriptionProps = {
  reserveFive: number
  reserveSeven: number
  offlineFive: number
  offlineSeven: number
}
export type BudgetTypeDescriptionDataItem = {
  type: string
  reserve: number
  now: number
  rent: number
  pickUp: number
  dropOff: number
}
export const TypeDetailChartOption = ({
  reserveFive,
  reserveSeven,
  offlineFive,
  offlineSeven
}: {
  reserveFive: number
  reserveSeven: number
  offlineFive: number
  offlineSeven: number
}) => ({
  title: {
    text: '用车类型',
    left: 'center'
  },
  tooltip: {
    trigger: ETriggerType.item,
    formatter: '{a} <br/>{b} : {c} ({d}%)'
  },
  legend: {
    orient: ELegendType.vertical,
    left: 'left',
    data: ['线下车队-五座', '线下车队-七座', '网约车-五座', '网约车-七座']
  },
  series: [
    {
      name: '用车类型',
      type: 'pie',
      radius: '55%',
      center: ['50%', '60%'],
      data: [
        { value: offlineFive, name: '线下车队-五座' },
        { value: offlineSeven, name: '线下车队-七座' },
        { value: reserveFive, name: '网约车-五座' },
        { value: reserveSeven, name: '网约车-七座' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
})
export const TypeChartOption = ({
  onlineCount,
  offlineCount
}: {
  onlineCount: number
  offlineCount: number
}) => ({
  title: {
    text: '用车类型',
    left: 'center'
  },
  tooltip: {
    trigger: ETriggerType.item,
    formatter: '{a} <br/>{b} : {c} ({d}%)'
  },
  legend: {
    orient: ELegendType.vertical,
    left: 'left',
    data: ['线下车队', '网约车']
  },
  series: [
    {
      name: '用车类型',
      type: 'pie',
      radius: '55%',
      center: ['50%', '60%'],
      data: [
        { value: offlineCount, name: '线下车队' },
        { value: onlineCount, name: '网约车' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
})
export const BudgetChartOption = (budget: any) => ({
  tooltip: {
    trigger: ETriggerType.axis,
    axisPointer: {
      type: EAxisPointer.cross,
      crossStyle: {
        color: '#999'
      }
    }
  },
  legend: {
    data: ['立即用车', '线下车队', '平均费用']
  },
  xAxis: [
    {
      type: EAxisType.category,
      data: ['立即用车', '预约用车', '接机', '送机', '包车'],
      axisPointer: {
        type: EAxisPointerType.shadow
      }
    }
  ],
  yAxis: [
    {
      type: EAxisType.value,
      name: '用车',
      // min: 0,
      // max: 250,
      interval: 5,
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: EAxisType.value,
      name: '费用',
      // min: 0,
      // max: 25,
      // interval: 50,
      axisLabel: {
        formatter: '{value} ￥'
      }
    }
  ],
  series: [
    {
      name: '立即用车',
      type: 'bar',
      data: [
        budget[0]?.now,
        budget[0]?.reserve,
        budget[0]?.pickup,
        budget[0]?.dropoff,
        budget[0]?.rent
      ]
    },
    {
      name: '线下车队',
      type: 'bar',
      data: [
        budget[1]?.now,
        budget[1]?.reserve,
        budget[1]?.pickup,
        budget[1]?.dropoff,
        budget[1]?.rent
      ]
    },
    {
      name: '平均费用',
      type: 'line',
      yAxisIndex: 1,
      data: [
        budget[2]?.now,
        budget[2]?.reserve,
        budget[2]?.pickup,
        budget[2]?.dropoff,
        budget[2]?.rent
      ]
    }
  ]
})

export const CarTypeDescription = ({
  reserveFive,
  reserveSeven,
  offlineFive,
  offlineSeven
}: CarTypeDescriptionProps) => (
  <Card title='用车类型'>
    <Descriptions
      layout='vertical'
      bordered
      column={5}
      style={{ width: '60%' }}
    >
      <Descriptions.Item label='预约车-五座'>{reserveFive}</Descriptions.Item>
      <Descriptions.Item label='预约车-七座'>{reserveSeven}</Descriptions.Item>
      <Descriptions.Item label='线下车队-五座'>{offlineFive}</Descriptions.Item>
      <Descriptions.Item label='线下车队-七座'>
        {offlineSeven}
      </Descriptions.Item>
      <Descriptions.Item label='总数'>
        {reserveFive + reserveSeven + offlineFive + offlineSeven}
      </Descriptions.Item>
    </Descriptions>
  </Card>
)
export const BudgetTypeDescription = ({
  dataSource
}: {
  dataSource: BudgetTypeDescriptionDataItem[] | []
}) => {
  const columns = [
    {
      title: '用车类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '立即用车',
      dataIndex: 'now',
      key: 'now'
    },
    {
      title: '预约用车',
      dataIndex: 'reserve',
      key: 'reserve'
    },
    {
      title: '接机',
      dataIndex: 'pickup',
      key: 'pickUp'
    },
    {
      title: '送机',
      dataIndex: 'dropoff',
      key: 'dropOff'
    },
    {
      title: '包车',
      dataIndex: 'rent',
      key: 'rent'
    }
  ]
  return (
    <Card title='用车类型及费用'>
      <Table bordered columns={columns} dataSource={dataSource} pagination={false} />
    </Card>
  )
}
export const CarTypeChart = ({
  leftChartOption,
  rightChartOption
}: {
  leftChartOption: EChartOption
  rightChartOption: EChartOption
}) => (
  <Row>
    <Col span={12}>
      <ReactEchartsCore
        echarts={echarts}
        option={leftChartOption}
        notMerge={true}
        lazyUpdate={true}
        theme={'theme_name'}
      />
    </Col>
    <Col span={12}>
      <ReactEchartsCore
        echarts={echarts}
        option={rightChartOption}
        notMerge={true}
        lazyUpdate={true}
        theme={'theme_name'}
      />
    </Col>
  </Row>
)
export const CarBudgetChart = ({ option }: { option: EChartOption }) => (
  <ReactEchartsCore
    echarts={echarts}
    option={option}
    notMerge={true}
    lazyUpdate={true}
    theme={'theme_name'}
  />
)
