import React, { FC } from 'react'
import { useCarScheduleComplianceRisksQuery } from '../graphql'
import { Loading3QuartersOutlined } from '@ant-design/icons'

const ScheduleRiskItem: FC<{
  scheduleId: string | number | undefined
  type: string | string[]
  risks?: any
}> = ({ scheduleId, type, risks }) => {
  if (!scheduleId) return <span>-</span>
  const [{ fetching, data }] = useCarScheduleComplianceRisksQuery({
    pause: !!risks,
    variables: {
      scheduleId:
        typeof scheduleId === 'string' ? scheduleId : scheduleId.toString()
    }
  })
  if (fetching) {
    return <Loading3QuartersOutlined />
  }
  // 同时支持传入单个type和type数组
  const types = Array.isArray(type) ? type : [type]

  const riskItems =
    (
      risks || (data?.risks as { [key: string]: any } | undefined)
    )?.riskItems?.filter((r: any) => types.includes(r.type)) || []

  const hasError =
    riskItems.findIndex((item: any) => !['N', '0', ''].includes(item.value)) >
    -1

  return <span>{hasError ? '是' : '-'}</span>
}

export default ScheduleRiskItem
