import React from 'react'
import { Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import { MeetingStatusSelector } from '../../components/SelectorCollection'
import DatePicker from '../../components/CustomDatePicker'
import { MeetingListQuery, UpMeetingBudgetType } from '../../graphql'
import { CheckCarUsageAnalyticsButton } from '../../components/MeetingActionButton'
import Button from 'antd/es/button'

//搜索字段声明
export const searchFields = [
  {
    name: 'status',
    label: '会议状态',
    span: 4,
    render() {
      return <MeetingStatusSelector />
    }
  },
  {
    name: 'code',
    label: '会议编号',
    span: 5,
    render() {
      return <Input allowClear />
    }
  },
  {
    name: 'name',
    label: '会议名称',
    span: 5,
    render() {
      return <Input allowClear />
    }
  },
  {
    name: 'date',
    label: '会议日期',
    span: 6,
    render() {
      return <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
    }
  }
]

export const useTableColumns = (): ColumnsType<
  MeetingListQuery['meetings']['items'][0]
> => {
  return [
    {
      title: '会议代码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '会议名称',
      dataIndex: 'name',
      key: 'name',
      render(v, meeting) {
        return <Link to={`/carUsageAnalyticsDetail/${meeting.id}`}>{v}</Link>
      }
    },
    {
      title: '联系人',
      dataIndex: 'ownerName',
      key: 'ownerName',
      render(v) {
        return v || '--'
      }
    },
    {
      title: '会议城市',
      dataIndex: 'center',
      key: 'name',
      render(v) {
        return v?.name
      }
    },
    {
      title: '会议酒店',
      dataIndex: 'bmsMeetingHotel',
      key: 'hotel'
    },
    {
      title: '会议开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      render(v) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '会议结束时间',
      dataIndex: 'endTime',
      key: 'endTime',
      render(v) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '用车行程',
      dataIndex: 'travelOrders',
      align: 'center',
      key: 'travelOrders',
      render(_, meeting) {
        return meeting.budgets.items
          .find(
            (i) => i.type === UpMeetingBudgetType.ShortDistanceTransportationFee
          )
          ?.orders.reduce((total, current) => (total += current.num || 0), 0)
      }
    },
    {
      title: '会议状态',
      dataIndex: 'bizStatus',
      key: 'bizStatus',
      render(v) {
        return <span style={{ color: v?.color }}>{v?.text}</span>
      }
    },
    {
      title: 'PO单',
      dataIndex: 'po',
      key: 'po'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(_, meeting) {
        // 已完成会议才能查看报告
        const canCheckAnalytics = ['U_MEETING_FINISHED'].includes(
          meeting.status || ''
        )
        return (
          <Button.Group>
            {canCheckAnalytics && (
              <CheckCarUsageAnalyticsButton meeting={meeting} />
            )}
          </Button.Group>
        )
      }
    }
  ]
}

export type FieldTypes = {
  cityId?: string
  code?: string
  name?: string
  date?: [Dayjs | undefined, Dayjs | undefined]
}
